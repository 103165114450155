.news-team-section {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 1rem;
    background-color: white;
}

.news-text-container {
    display: flex;
    flex-direction: column;
    max-width: 420px;
    align-items: center;
    text-align: center;
}

.news-team-title {
    font-size: 2rem;
    font-weight: bold;
}

.news-team-container {
    display: flex;
    padding: 4rem 0rem;
}

.member-container,
.member-container-last {
    padding: 0rem 1rem;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.member-container-last {
    margin-right: 0rem;
}

.member-image {
    width: 200px;
    border-radius: 100%;
}

.member-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1rem 0rem 0rem 0rem;
}

.member-description {
    font-size: 1.2rem;
}

.member-job {
    text-align: center;
    text-wrap: pretty;
}

@media screen and (max-width: 1024px) {
    .news-team-subtitle {
        max-width: 600px;
    }
    .news-team-container {
        padding-top: 2rem;
    }

    .news-team-container {
        flex-direction: column;
        padding-bottom: 0rem;
    }

    .member-container,
    .member-container-last {
        margin-bottom: 2rem;
        margin-right: 0rem;
        padding: 0rem;
    }
}

@media screen and (max-width: 786px) {
    .news-team-section {
        padding: 2rem 1rem;
    }

    .news-text-container {
        max-width: 100%;
    }
}

@media screen and (min-width: 1200px) {
    .member-container {
        margin-right: 3rem;
    }
}
