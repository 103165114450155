.course-page {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4.5rem;
}

.course-title {
    color: black;
}

.course-pdf {
    width: 90vw;
    height: 75vh;
    margin-bottom: 2rem;
}

@media screen and (max-width: 786px) {
    .course-page {
        margin-top: 5rem;
        padding-bottom: 2.2rem;
    }
    .course-pdf {
        height: 82vh;
    }
}

@media screen and (min-width: 786px) {
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1400px) {
}
