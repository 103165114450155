//
// _powerbi.scss
//


.Embed-container {
    height: 900px;
    width: 1500px;
    margin: auto;
    background-color: #fff;

}