.topbar {
    position: fixed;
    backdrop-filter: blur(10px);
    z-index: 10;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    padding: 0.3rem 1rem 0rem 1rem;
}

.AI-dropdown {
    display: none;
}

.Map-dropdown {
    display: none;
}

.Data-dropdown {
    display: none;
}

.nav-content {
    display: flex;
    width: 70%;
    justify-content: flex-end;
    align-items: center;
}

.lines {
    transition: all 0.4s ease;
    position: absolute;
}

.top-line {
    top: 0;
}
.middle-line {
    top: 7px;
}
.bottom-line {
    top: 14px;
}

.line {
    position: relative;
    width: 32px;
    height: 3px;
    transition: all 0.4s ease;
}

.mobile {
    display: none;
}

.nav-element {
    padding-right: 24px;
    transition: all 0.4s ease-in-out;
}

.nav-element:hover {
    color: grey !important;
}

.mobile-menu {
    backdrop-filter: blur(20px);
    z-index: 10;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: self-end;
    padding: 0rem 0rem 0.5rem 0.5rem;
    right: 0;
    top: 58px;
}

.Education-dropdown {
    backdrop-filter: blur(10px);
    z-index: 10;
    position: absolute;
    padding: 0rem 0rem 0.5rem 0.5rem;
    top: 62px;
    right: 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

@media screen and (max-width: 1023px) {
    .nav-content {
        margin-top: 10px;
        margin-bottom: 26px;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin-right: 0.5rem;
    }

    .mobile {
        display: flex;
    }

    .desktop {
        display: none;
    }
}
