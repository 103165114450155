// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
  height: 100%;
}

body {
  overflow-x: hidden;
  min-height: 100%;
  height: 100%;
}


.btn-z-index {
  position: relative;
  z-index: 9999;
  font-weight: bold;
}

.svg-z-index {
  position: relative;
  z-index: 1;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

/* Move down content because we have a fixed navbar that is 3.5rem tall */
body {
  // color: #354576; // override bootstrap
}

.jumbo-image {
  padding: 3.5rem;
background-image: url(../../../images/wb_lgBg.jpg);
background-size: cover;
}

.jumbo-text {
color: #fff;
text-shadow: 2px 2px 4px #000000;
}
a.jumbo-text {
color: #0099ff;
text-shadow: 2px 2px 4px #000000;
}

.bigger-text {
font-size: 2.5rem;
}

.center {
margin: auto;
}

.green {
color: #0acf97;
}
.container-right {
  
  color: #fff;
  text-shadow: 2px 2px 4px #000000;
  
}
.right-align {
  text-align: right;
}

.v-align-bottom {
  vertical-align: bottom;
}

.loading-spinner {
  display: inline-block;
  width: 100w;
  position: relative;
  margin: auto;
  // width: 64px;
  // height: 64px;
}

.loader-container {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  // background-color: #444444;
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}

.logo1 {
  margin: auto;
  padding: 20px;
}


.history-row {
  background-color: #313a46;
  border-radius: 5px;
  margin: 15px;
  padding: 0.5% 7% 0.5% 7%;
  min-height: auto;
}

.history-col {
  border-radius: 5px;
  background-color: #3A526D;
  margin-left: 10px;
}

.br-scale {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: 2.5vw; /* change this to whatever height you want it */
}

.hint-button {
  font-size: 0.75vw;
  margin-left: 32%;
  color: white;
  background-color: #3079e0;
  border-radius: 5px;
  border-color: #3079e0;
}

.submit-button {
  font-size: 0.75vw;
  color: white;
  background-color: #3079e0;
  border-radius: 5px;
  border-color: #3079e0;
}

.spinner {
  height: 100%;
}