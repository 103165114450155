.news-history-section {
    padding: 3.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e7ebef;
}

.historical-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.news-carousel {
    overflow: hidden;
    width: 600px;
    height: 340px;
}

.news-carousel-container {
    display: flex;
    align-items: center;
}

.news-carousel-image {
    width: 600px;
    height: 340px;
}

.news-carousel-image-container {
    padding: 1.5rem;
}

@media screen and (max-width: 1024px) {
    .news-carousel {
        height: 280px;
    }
}

@media screen and (max-width: 760px) {
    .news-carousel {
        width: 90vw;
        height: 240px;
    }
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 1600px) {
}
