.Education {
    background-color: #e7ebef;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
}

.grades {
    background-image: linear-gradient(180deg, #e7ebef, white);
    width: 100%;
}

.education-container {
    background-color: #e7ebef;
    width: 100%;
    padding: 4rem 1rem 0.5rem 1rem;
}

.content-container {
    background-image: linear-gradient(180deg, #e7ebef, white);
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grades-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    row-gap: 2rem;
    padding-bottom: 1.5rem;
    padding-top: 0.75rem;
    margin: auto;
}

.courses-container {
    display: flex;
}

.slide {
    transition: all 0.4s ease-in-out;
}

.slide:hover {
    transform: translateY(-8px);
}

.grade-card {
    cursor: pointer;
}

@media screen and (max-width: 786px) {
    .courses-container {
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;
        width: 90vw;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .grades-container {
        width: 100%;
    }

    .content-container {
        padding-top: 2.5rem;
    }
    .grades {
        padding-left: 0rem;
    }
}

@media screen and (min-width: 786px) {
    .courses-container {
        margin-bottom: 4rem;
        margin-top: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 90vw;
    }
    .grades-container {
        max-width: 100vw;
    }

    .Education {
        align-items: center;
    }
    .grades {
        padding-left: 0rem;
    }
}

@media screen and (min-width: 1024px) {
    .courses-container {
        flex-wrap: wrap;
    }
    .grades {
        padding-left: 1rem;
    }
}

@media screen and (min-width: 1400px) {
    .grades-container {
        max-width: 100%;
        margin-right: 2rem;
        margin: auto;
    }
}
