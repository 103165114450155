.plain-btn {
    border: none;
    background-color: #0083c9;
    cursor: pointer;
    font: inherit;
    outline: inherit;
    color: white;
    border-radius: 4px;
}

.grow {
    transition: all 0.4s ease;
}

.grow:hover {
    transform: scale(1.03);
}

.plain-btn:active {
    transform: scale(0.98);
}
