//
// footer.scss
//

.footer {
    background-color: white; // CHANGE FOOTER BACKGROUND COLOR HERE
    bottom: 0;
    padding: 50px 24px;
    right: 0;
    color: #{map-get($grays, '600')};
    left: $leftbar-width;
    transition: $transition-base;

    .footer-links {
        a {
            color: #{map-get($grays, '600')};
            // margin-left: 1.5rem;
            transition: all 0.4s;
            &:hover {
                color: #{map-get($grays, '300')};
            }
            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .footer {
        left: 0 !important;
        text-align: center;
    }
}

// Used for account authentication pages
.footer-alt {
    left: 0;
    border: none;
    text-align: center;
}

// Layout - Topnav
body[data-layout='topnav'] {
    .footer {
        left: 0 !important;
        padding: 19px 0 20px;
    }
    &[data-layout-mode='boxed'] {
        .footer {
            max-width: $boxed-layout-width;
        }
    }
}

// Dark mode
body[data-layout-color='dark'] {
    .footer {
        border-top: 1px solid #{rgba(map-get($dark-grays, '600'), 0.2)};
        color: #{map-get($dark-grays, '600')};

        .footer-links {
            a {
                color: #{map-get($dark-grays, '600')};
                &:hover {
                    color: #{map-get($dark-grays, '900')};
                }
            }
        }
    }
}

@media screen and (max-width: 1180px) {
    .footer {
        padding-top: 30px;
    }
}
