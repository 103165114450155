.copyright {
    padding-bottom: 24px;
}

.footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-col {
    width: auto !important;
}

.logo-image {
    width: auto;
    height: 60px;
}

.logo-image-fau {
    /* width: 300px; */
    height: 120px;
}

.big-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;
}

.small-container {
    display: none;
}

.logo-container {
    padding: 12px 20px;
}

.logo-container-fau {
    padding: 20px 24px;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 786px) {
    .logo-image-fau {
        width: 75vw;
        height: 150px;
    }
    .footer-container {
        flex-direction: column;
    }
    .logo-container {
        padding: 8px 12px;
    }
    .logo-container-fau {
        padding: 0.75rem 1rem;
    }
}
@media screen and (max-width: 1180px) {
    .footer-content {
        flex-direction: column;
    }

    .big-container {
        display: none;
    }

    .small-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 12px;

        margin-bottom: 12px;
    }
    .footer-container {
        display: flex;
        align-content: center;
    }
}
