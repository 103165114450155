.news-page {
    display: flex;
    flex-direction: column;
    margin-top: 7rem;
    color: black;
}

.news-hero-section {
    display: flex;
    color: black;
    text-wrap: pretty;
    padding-bottom: 3rem;
}

.news-title {
    margin-bottom: 1rem;
}

.news-element {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
}

.news-images {
    width: 220px;
    height: 150px;
    margin-right: 1rem;
}

.news-video {
    width: 220px;
    height: 150px;
    margin-right: 1rem;
}

.right-side {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.left-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
}

.news-content {
    display: flex;
}

@media screen and (max-width: 1024px) {
    .news-hero-section {
        flex-direction: column;
        justify-content: center;
    }

    .left-side,
    .right-side {
        align-items: center;
    }

    .left-side {
        text-align: center;
    }

    .right-side {
        margin-top: 1.5rem;
    }

    .news-content {
        align-items: flex-start;
    }

    .news-content {
        flex-direction: row;
        align-items: flex-start;
        overflow: auto;
        width: 100%;
    }

    .news-element {
        flex-direction: column;
        margin-right: 1rem;
        width: 80vw;
    }

    .news-images {
        width: 300px;
        height: 200px;
    }

    .news-video {
        width: 300px;
        height: 200px;
    }

    .left-content {
        width: 70%;
    }
    .ambassador-content {
        margin-bottom: 1rem;
    }

    .news-description {
        margin-top: 0.75rem;
    }
}

@media screen and (max-width: 786px) {
    .left-content {
        width: 90vw;
    }
}

@media screen and (min-width: 1024px) {
    .left-content {
        width: 80%;
    }

    .left-side {
        align-items: flex-start;
        width: 35%;
        margin-left: 5%;
        margin-bottom: 10%;
    }
    .news-hero-section {
        flex-direction: row;
        justify-content: space-between;
    }

    .left-content {
        max-width: 430px;
    }

    .news-element {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
        align-items: center;
    }

    .news-content {
        flex-direction: column;
    }

    .right-side {
        width: 55%;
        max-width: 700px;
        margin-right: 2%;
    }
}

@media screen and (min-width: 1200px) {
    .left-side {
        margin-left: 8%;
    }
    .right-side {
        max-width: 730px;
        margin-right: 5%;
    }
}

@media screen and (min-width: 1600px) {
    .left-side {
        margin-left: 10%;
    }
}
