.slide {
    transition: all 0.4s ease-in-out;
}

.slide:hover {
    transform: translateY(-6px);
}

@media screen and (max-width: 786px) {
    .image-card,
    .image-card-sm {
        width: 74vw;
        height: 160px;
    }
    .image-card-container {
        margin-bottom: 1rem;
    }
}

@media screen and (min-width: 786px) {
    .image-card {
        width: 260px;
        height: 150px;
    }
    .image-card-sm {
        width: 220px;
        height: 130px;
    }
    .image-card-sm {
        margin-top: 1rem;
    }
}

@media screen and (min-width: 1024px) {
    .image-card {
        width: 360px;
        height: 180px;
    }

    .image-card-sm {
        width: 280px;
        height: 150px;
    }
}
