.news-contact-us {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 1rem;
}

.contact-individual-content {
    color: white;
    text-decoration: none;
}
.contact-individual-content:hover {
    color: rgb(172, 169, 169);
}

.contact-us-content {
    margin-top: 2rem;
    padding: 2.25rem;
    background-color: #006296;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
    border-radius: 0.5rem;
}

.contact-us-title {
    font-size: 2rem;
    font-weight: bold;
}

.contact-individual-section {
    color: white;
}

.contact-individual-title {
    font-size: 1.5rem;
    font-weight: bold;
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 786px) {
    .contact-us-content {
        grid-template-columns: 1fr;
    }
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 1600px) {
}
